import React, { Component } from 'react';

export default class SadEmoji extends Component {
  static propTypes = {

  };

  render() {
    return (
      <div className="home-sad-emoji">
        <div class='container'>
          <div class='tear'></div>
          <div class='tear2'></div>
          <div class='face'>
              <div class='eyebrow'>︶</div>
              <div class='eyebrow'>︶</div>
              <div class='eye'></div>
              <div class='eye'></div>
              <div class='mouth'></div>
          </div>
        </div>
      </div>
    );
  }
}
