import React from 'react';
import { SadEmoji } from '/';

export default function GoodByePage() {
  return (
    <div className="home-good-bye-page">
      <header className="app-header">
        <img src={require('../../images/shisha-game-logo.png')} className="shisha-game-logo" alt="Shisha-Game.net - Logo" />
      </header>
      <div className="content">
          <div className="app-intro">
            <h1>Wir sagen DANKE...</h1>
            <div className="centered">
              <SadEmoji />
            </div>
            
            <p><b>Anfang 2007</b> erblickte das Shisha-Game durch einen Zufall das Licht des <i>World-Wide-Webs</i>: Aus der Idee, Shisha-Komponenten dynamisch konfigurieren zu können, entwickelte sich schnell eines der kurisosesten Browser Games seiner Zeit.</p>
            <p>Mit zwischenzeitlich knapp einer <b>halben Millionen Mitglieder</b> wuchs das Shisha-Game zu einer riesige Community, welche die Entwicklung des Spiels maßgeblich mitgestaltete.
            Mit Freude erinneren wir uns an Events wie den <a href="https://www.youtube.com/watch?v=FuRVbtodIkk&t=1s" target="_blank" rel="noopener noreferrer">Build-Your-Shisha-Contest</a>. Vielen Dank dafür!</p>

            <h2>... und verabschieden uns!</h2>
            <p>Nach nun <b>knapp 14 Jahren</b> habe ich mich schweren Herzens dazu entschlossen, dass Shisha-Game in seiner bisherigen Form am 31.12.2020 <i>vom Netz</i> zu nehmen.
            Die Gründe hierfür sind vielseitig - nicht zuletzt gibt es unüberwindbare Hürden, die eine zeitgemäße Weiterentwicklung unmöglich machen.</p>
            <img src={require('../../images/shisha-clear-animation.gif')} alt="Shisha auseinanderbauen"/>

            <h2>Eine Sache noch:</h2>
            <p>Wenn dir der Community-Faktor des Shisha-Games besonders wichtig war, lade ich dich herzlich dazu ein <b>Schnäppo</b> auszuprobieren.</p>
            <p>Bei Schnäppo handelt es sich um ein Schnäppchen-Portal mit Cashback-Funktionalität, welches ich gemeinsam mit zwei Freunden gegründet und entwickelt habe.</p>
            <a class="schnaeppo-link" href="https://www.schnaeppo.de" target="_blank" rel="noopener noreferrer"><img src={require('../../images/schnaeppo-logo.png')} alt="Schnäppo Logo"/></a>
            <p>Schau dir mal <a href="https://www.schnaeppo.de" target="_blank" rel="noopener noreferrer">Schnäppo.de - Das Schnäppchen-Portal</a> an. In der <a href="https://www.schnaeppo.de/deals" target="_blank" rel="noopener noreferrer">Schnäppchen</a>-Rubrik findest du täglich neue Angebote verschiedener Online-Shops.</p>
            <p>Hier werden neben den limitierten Angeboten der Shops auch Preisfehler gelistet, bei denen man richtig abstauben kann.</p>
            <p>Wenn dir "herkömmliche" Angebote nicht ausreichen, wirst du dich über die <a href="https://www.schnaeppo.de/deals/cashback" target="_blank" rel="noopener noreferrer">Cashback</a>-Rubrik freuen:<br />
            Hier bekommen registrierte Schnäppo-Mitglieder (zu den ohnehin attraktiven Rabatten) zusätzlich noch ein Cashback zum Deal!</p>
            <p><b>Das war aber noch nicht alles:</b> Wenn du selbst ein gutes Angebot entdeckt hast, kannst du es bei Schnäppo einstellen und bekommst eine kleine Provision für jeden, der über deinen Deal einkauft.</p>
          </div>
        </div>
        <footer className="app-footer">Lukas Werner | <a href="mailto:info@shisha-game.net">info@shisha-game.net</a> | Kurt-Huber-Straße 37 - 85055 Ingolstadt</footer>
      </div>    
  );
}
