import React from 'react';
import {
  Redirect
} from "react-router-dom";

export default function PageNotFound() {
  return <Redirect
  to={{
    pathname: "/",
  }}
/>;
}
